/**
 * !scroll ---------------------------------------------------
 */
export const ScrollAnchor = () => {
  const _scrollAnchor = document.querySelectorAll('.scroll-anchor');
  if (_scrollAnchor.length > 0) {
    _scrollAnchor.forEach((n) => {
      n.addEventListener('click', (e) => {
        e.preventDefault();
        const _self = e.currentTarget;
        const href = _self.getAttribute('href');
        const target = document.querySelector(href === '#' || href === '' ? 'html' : href);
        const p = new Promise((resolve) => resolve((html.style.scrollBehavior = 'smooth')));
        const adjustment = !!target.dataset.aos && target.dataset.aos === 'fade-up' ? 100 : 0;
        return p
          .then((resolve) => {
            window.scrollTo(
              0,
              target.getBoundingClientRect().top +
                (window.pageYOffset || document.documentElement.scrollTop) -
                adjustment
            );
            return resolve;
          })
          .then((resolve) => {
            html.style.scrollBehavior = '';
            return resolve;
          });
      });
    });
  }
};

export default ScrollAnchor;
