/**
 * 汎用の状態切り替えスイッチ
 * data-switch属性がついているelementをクリックするとdata-switch属性の値に該当するelementのclassをtoggleする。
 * 基本的にはclass="active"がtoggleされるが、data-switch-class属性がある時はその値をclassをtoggleする。
 * 該当するindexのelementのみclassを切り替える。
 * 例: button[data-switch=".target"]が2個ある場合はそれぞれdocument.querySelectorAll('.target"')[0]と[1]がtoggleの対象となる。
 *     document.querySelectorAll('.target"')[0]のみ存在して[1]以降がないときは[0]を対象とする。
 * data-switch-multi属性がある時は該当するindexとそれ以外も含む全てのelementのclassをtoggleする。
 */
export const GeneralSwitch = () => {
  const _generalSwitch = document.querySelectorAll('[data-switch]');
  if (_generalSwitch) {
    _generalSwitch.forEach((item) => {
      item.addEventListener('click', (e) => {
        const self = e.currentTarget;
        if (!item.type === 'submit') self.preventDefault();

        const _targetSelector = self.dataset.switch;
        const _activateClass = self.dataset.switchClass || 'active';

        const elements = document.querySelectorAll(
          `${self.tagName.toLocaleLowerCase()}[data-switch='${_targetSelector}']`
        );
        const elementsIndex = elements.length > 1 ? Array.from(elements).findIndex((element) => element === self) : 0;

        const _targets = document.querySelectorAll(`${_targetSelector}`);
        if (self.dataset.switchMulti) {
          return _targets.forEach((t) => t.classList.toggle(_activateClass));
        }
        const _activateTarget = _targets[elementsIndex] ? _targets[elementsIndex] : _targets[0];
        _activateTarget && _activateTarget.classList.toggle(_activateClass);
      });
    });
  }
};

export default GeneralSwitch;
