import FastClick from 'fastclick/lib/fastclick';
// import AOS from 'aos';

import GeneralSwitch from './component/GeneralSwitch';
import ScrollAnchor from './component/ScrollAnchor';

((window, document, html, body) => {
  if ('addEventListener' in document) {
    document.addEventListener(
      'DOMContentLoaded',
      () => {
        FastClick.attach(document.body);
      },
      false
    );
  }
  // if (typeof AOS === 'object' && typeof AOS.init === 'function') {
  //   AOS.init();
  // }

  GeneralSwitch();
  ScrollAnchor();

  const _setHeaderColor = document.querySelectorAll('[data-shc]');
  const _header = document.getElementById('header');
  const changeHeaderColor = () => {
    if (_setHeaderColor.length > 0) {
      const headerHeight = _header.clientHeight;
      _setHeaderColor.forEach((e, index) => {
        if (e.getBoundingClientRect().top > headerHeight) {
          if (index === 0) body.dataset.hhc = '';
          return;
        }
        if (e.getBoundingClientRect().top < headerHeight && e.getBoundingClientRect().bottom > headerHeight / 2) {
          body.dataset.hhc = e.dataset.shc;
        } else {
          body.dataset.hhc = '';
        }
      });
    }
  };
  let rAFflag = false;
  const rAFAction = () => {
    if (!rAFflag) {
      requestAnimationFrame(function () {
        rAFflag = false;
        changeHeaderColor();
      });
      rAFflag = true;
    }
  };
  window.addEventListener('scroll', rAFAction, { passive: true });
  changeHeaderColor();
})(window, document, document.documentElement, document.body);
